.dropdown-toggle::after {
  display: none !important;
}

.template-dropdown :hover {
  background-color: transparent;
}

.anticon svg {
  color: #4b5675;
}

.ant-picker-input input {
  color: #000000 !important;
}

.ant-picker-input input::placeholder {
  color: #4b5675 !important;
}

.word-break {
  word-break: break-all;
}